// menu.scss
@import "../../variables";

.menu-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  //box-shadow: ;

  .menu-icon {
    color: $mainGrey;
    margin-bottom: 3px;
  }

  .menu-top-section {
    display: flex;
    flex-direction: column;

    .menu-toggle-block {
      display: flex;
      align-items: center;
      padding-top: 32px;

      .logo {
        padding-left: 80px;
        padding-right: 30px;
      }

    }

    .company-name-empty-block {
      height: 10vh; // или другой нужный размер
    }

    .company-name-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 36px;

      .company-name {
        color: $mainGrey;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.02em;
        text-align: left;
        margin-left: 24px;
      }

      .change-button {
        height: 2rem;
        background-color: $lightGrey;
        color: $mainGrey; // Цвет текста кнопки
        border: none;
        font-size: .8rem;
        //font-weight: 400;
        //line-height: 24px;
        gap: 0
        //letter-spacing: 0.1em;
        //text-align: left;

      }

    }

    .menu-options {
      list-style-type: none;
      padding-left: 2rem;
      margin-bottom: 20px;

      .menu-item-link {
        .menu-item {
          text-decoration: none;
          color: $mainBlack; // Укажите нужный цвет текста
        }

        &:hover {
          color: #4BBD96;
        }

        //padding-: 0.5rem 0;
        color: $mainBlack; // Укажите нужный цвет текста
        cursor: pointer;
        font-size: 1.2rem;
        margin-bottom: 1.5rem;

        .active {
          color: #4BBD96;
        }
      }

    }
  }

  .menu-bottom-section {
    margin-top: auto; // Прижимаем к низу
    padding: 2rem 0.5rem 2rem 2rem;
    display: flex;

    .avatar-block {
      min-width: 64px;

      .avatar {
        margin-bottom: 10px;
      }
    }

    //
    //.circle-avatar {
    //  width: 64px; /* Ширина круга */
    //  height: 64px; /* Высота круга */
    //  border-radius: 50%; /* Создаем круглую форму */
    //  border: 1px solid #4BBD96; /* Граница круга */
    //  display: flex;
    //  justify-content: center;
    //  align-items: center;
    //  background-color: $mainGrey; /* Цвет фона круга */
    //  padding: 8px; /* Внутренний отступ от содержимого до границы */
    //  box-sizing: border-box; /* Учитываем padding и border в общей ширине и высоте */
    //}

    .profile-section {
      flex-grow: 1;
      position: relative;
      overflow: hidden;

      .user-name {
        color: $mainBlue; // Укажите нужный цвет текста
        margin: 5px 0;
        font-size: 1.1rem;
        font-weight: 500;
        overflow: hidden;
      }

      .profile-logout-section {
        justify-content: space-around;

        a {
          text-decoration: none;
          color: #232427;
          font-weight: 400;
          cursor: pointer;
        }

        color: $mainBlack;

        .profile-button,
        .logout-button {
          padding-right: 1rem;
        }
      }
    }
  }

}

.menu-change-firm {
  ul {
    padding: 0;
  }

  .MuiPaper-root.MuiPopover-paper.MuiMenu-paper {
    border-radius: 8px;
  }
}

.blue-color {
  color: $mainBlue
}
