@import "../../variables";

.breadcrumb {
  background: transparent; /* Убираем фон */
  padding: 0; /* Убираем отступы */
  margin-bottom: 0; /* Убираем отступ снизу, если он есть */
  /* Добавляем другие стили, если это необходимо */
}

.breadcrumb-item + .breadcrumb-item::before {
  content: "\25B7"; /* Изменяем разделитель на знак ">" */
  padding: 0 8px; /* Добавляем немного пространства вокруг разделителя */
  color: $mainGrey; /* Цвет разделителя, возможно, потребуется настроить */
  font-weight: bold;
  font-size: 14px;
  margin: 0 1rem;
}

.breadcrumb-item {
  font-size: 1rem; /* Размер шрифта для элементов хлебных крошек */
  letter-spacing: 0.1rem; /* Межбуквенный интервал */
  text-transform: uppercase;
  a {
    color: $black;
    text-decoration: none;
  }
}

.breadcrumb-item.active {
  color: $mainGreen; /* Цвет для активного элемента хлебных крошек */
  //font-weight: bold; /* Сделать текст жирным */
}
