@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap');
@import "variables";

:export {
  mainGrey: $mainGrey;
  mainBlack: $mainBlack;
  mainWhite: $white;
  mainBlue: $mainBlue;
  mainGreen: $mainGreen;
  lightGrey: $lightGrey;
  mainRed: $mainRed;
  mainBlueRGBa: $mainBlueRGBa;
  qbTextBlack: #393A3D;
  qbBorderGrey: #6B6C72;
  qbBorderGreyHover: rgba(107, 108, 114, 0.1);
}

@font-face {
  font-family: 'AvenirMediumFont';
  src: url('../public/avenir/avenir_roman_12.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: 'Source Code Pro', Menlo, Monaco, Consolas, 'Courier New', monospace;
  font-size: 16px;
}

a {
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background-color: $lightGrey;
}

::-webkit-scrollbar-thumb {
  background-color: $mainGrey;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
