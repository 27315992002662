@import "../../../variables";

.timeZone {
  display: flex;
  cursor: pointer;
}

.clients-list-span {
  text-decoration: none;
  color: $mainBlue;
}

.primary {
  color: $mainBlue;
  cursor: pointer;
}
