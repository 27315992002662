@import "../../variables";
.userContainer {
  display: flex;
  align-items: center;
  height: 100vh;
  background-color: $lightGrey;
  overflow: hidden;

  .menu {
    width: 280px;
    min-width: 280px;
    height: 100%;
  }

  .contentContainerRow {
    box-sizing: border-box;
    width: calc(100vw - 280px);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;

    .contentContainer {
      box-sizing: border-box;
      padding: 32px;
      width: 100%;
      height: 100%;
      background-color: #ffffff;
      border-radius: 15px;
    }
  }
}
