/* styles.module.scss */

$mainGrey: #8189A6;
$mainRed: #E42F2F;
$lightGrey: #E0E3EB;
$mainBlack: #2D3140;
$mainBlue: #0B7DBC;
$mainBlueRGBa: rgba(11 ,125,188,0.1);
$mainGreen: #4BBD96;
$black: #232427;
$white: #FFFFFF;
