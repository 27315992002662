@import "../../../variables";

.container-auth-page {
  background-color: $mainGrey;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  .main-auth-form {
    box-sizing: border-box;
    padding: 2rem;
    width: 27.25rem; // 436px
    display: inline-flex;
    flex-direction: column;
    border-radius: 8px;
    justify-content: center;
    background: $lightGrey;
    gap: 2rem;

    .logo {
      height: 90px;
    }

    .input-form {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;

      .need-help-div {
        width: 100%;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        user-select: none;
        text-decoration: none;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 1rem .5rem 0 .5rem;

        a {
          color: $mainGreen;
        }
      }

    }

    .or-text {
      text-align: center;
      color: $mainGrey;
      font-size: 1.15rem;
    }

    .intuitButton {
      border-width: 2px;
      height: 2.5rem;
    }
  }
}
